import React, { ReactNode } from "react";
import { chakra, VisuallyHidden, useColorModeValue } from "@chakra-ui/react";
/**
 *
 * @param {string} label
 * @param {string} href
 * @param {ReactElemet} children
 * @return ReactElemt
 */
//  Social media button component
const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <>
      <chakra.button
        bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
        rounded={"full"}
        w={8}
        h={8}
        cursor={"pointer"}
        as={"a"}
        href={href}
        display={"inline-flex"}
        alignItems={"center"}
        justifyContent={"center"}
        transition={"background 0.3s ease"}
        _hover={{
          bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
        }}
      >
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    </>
  );
};

export default SocialButton;
